import { Badge } from 'components/Badge';
import Card from 'components/Card';
import { CardContent } from 'components/Card/CardContent';
import CardHeader from 'components/Card/CardHeader';

import { Metric } from 'models/metric';
import { kFormatter } from 'utils/numberFormatter';
import { formatName } from 'utils/formatName';

export type ComingSoonItem = {
  icon: string;
  title: string;
};

interface ThreatItems extends Metric {
  icon: React.ReactNode;
}

interface ThreatInferencesProp {
  className: string;
  threatTitle?: string;
  inferences: ThreatItems[];
  total: number;
  comingSoon?: ComingSoonItem[];
}

interface MetricDetails {
  name: string;
}

function getInferenceDetails(inference: Metric) {
  const inferenceDetails: Record<string, MetricDetails> = {
    display_name_vip: {
      name: 'VIP Account',
    },
    financial_request_intent: {
      name: 'Financial Request',
    },
    location_anamoly: {
      name: 'Location Anamoly',
    },
    suspicious_domain: {
      name: 'Suspicious Domain',
    },
    first_time_sender: {
      name: 'First Time Sender',
    },
    frequent_sender: {
      name: 'Frequent Sender',
    },
    request_for_action_intent: {
      name: 'Action Required',
    },
    sense_of_urgency_tone: {
      name: 'Urgency Detected',
    },
    regulator_domain_lookalike: {
      name: 'Regulator Domain Lookalike',
    },
    vendor_domain_lookalike: {
      name: 'Vendor Domain Lookalike',
    },
    malicious_url: {
      name: 'Malicious URL',
    },
    vip_impersonation: {
      name: 'VIP Impersonation',
    },
    regulator_impersonation: {
      name: 'Regulator Impersonation',
    },
    vendor_impersonation: {
      name: 'Vendor Impersonation',
    },
    financial_fraud: {
      name: 'Financial Fraud',
    },
    attorney_fraud: {
      name: 'Attorney Fraud',
    },
    invoice_fraud: {
      name: 'Invoice Fraud',
    },
    bec: {
      name: 'BEC',
    },
    malware: {
      name: 'Malware',
    },
  };

  return (
    inferenceDetails[inference.code] || {
      name: formatName(inference.code),
    }
  );
}

export function ThreatInferences({
  className,
  threatTitle,
  inferences = [],
  total,
  comingSoon = [],
}: ThreatInferencesProp) {
  return (
    <div className={className}>
      <Card width="100%">
        <CardHeader>
          <div className="flex items-center justify-between">
            <span className="text-xs">{threatTitle}</span>
            <Badge text={kFormatter(total)} bgColor="black" textColor="white" borderColor="black" />
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-8">
            {inferences?.map((inference) => {
              const inferenceDetails = getInferenceDetails(inference);
              return (
                <div key={inference.code} className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    {inference.icon}
                    <span className="text-black text-xs">{inferenceDetails.name}</span>
                  </div>
                  <div className="flex items-center gap-4">
                    <span className="text-xs">{inference.count}</span>
                  </div>
                </div>
              );
            })}
          </div>
          {comingSoon?.length > 0 && <div className="border-border-primary border-b-light my-6" />}
          <div className="flex flex-col gap-8">
            {comingSoon.map((comingSoonItem) => (
              <div key={comingSoonItem.title} className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <img src={comingSoonItem.icon} className="w-3.5 h-3.5" alt="metric-icon" />
                  <span className="text-black text-xs">{comingSoonItem.title}</span>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-xs text-light-grey">Coming Soon</span>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
