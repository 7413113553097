import { useEffect, useState, useMemo } from 'react';

import * as Tabs from '@radix-ui/react-tabs';
import { Badge } from 'components/Badge';
import AllEvents from 'components/Events';
import { Circle } from 'components/Marker/Circle';

import { BEC, IMPERSONATION, INSIDER_THREAT, MALWARE } from 'constants/tabName';

import { GetEventsQueryParams } from 'models/events';
import { ThreatType } from 'models/threatInferences';

import { useOrgId } from 'hooks/useOrgId';

import { useGetFlaggedEventsQuery } from 'service/eventsApi';
import { useGetThreatInferencesQuery } from 'service/threatInferences';
import { useGetMalwareDetectionsQuery } from 'service/malwareDetections';
import { useGetThreatCategoryStatsQuery, useGetThreatStatsQuery } from 'service/threats';

import { ReactComponent as ThreatWarningIcon } from 'assets/icons/threat-warning.svg';
import Loader from 'components/Loader';

import { ThreatInferences } from './ThreatInferences';
import { ThreatsFrequency } from './ThreatFrequency';
import { getLegendColor, getThreatCategory } from './data';
import { NoThreatFallback } from './NoThreatFallback';

export default function Threats() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [OrgId] = useOrgId();

  const { data: threatCategories, isLoading: tabsLoading } = useGetThreatCategoryStatsQuery({
    orgId: OrgId,
  });

  // tabCategoryToThreats maps the threat categories to there respective sub categories
  const tabCategoryToThreats = useMemo<Record<string, string>>(
    () => ({
      impersonation: `${ThreatType.VIPImpersonation},${ThreatType.VENDORImpersonation},${ThreatType.REGULATORImpersonation}`,
      malware: ThreatType.MALWARE,
      bec: `${ThreatType.BEC},${ThreatType.FINANCIAL_FRAUD},${ThreatType.ATTORNEY_FRAUD},${ThreatType.INVOICE_FRAUD}`,
      insider_threat: ThreatType.INSIDER_THREAT,
    }),
    []
  );

  // tabIdToName maps the tab ids coming from backend to frontend
  const tabIdToName: Record<string, string> = {
    impersonation: 'Impersonation',
    malware: 'Malware',
    bec: 'BEC',
    insider_threat: 'Insider Threat',
  };

  const getTabDisplayName = (id: string): string => {
    return tabIdToName[id] || id;
  };

  const [tableFilters, setTableFilters] = useState<GetEventsQueryParams>({
    limit: 10,
    offset: 0,
    from: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
    to: new Date().toISOString(),
    sortBy: 'time',
    sortOrder: 'desc',
    vector: '',
    threat: threatCategories ? tabCategoryToThreats[threatCategories[0].code] : '',
  });

  const { data: flaggedData, isLoading } = useGetFlaggedEventsQuery({
    orgId: OrgId,
    ...tableFilters,
  });

  const { data: inferenceData } = useGetThreatInferencesQuery({
    orgId: OrgId,
    threat: threatCategories ? tabCategoryToThreats[threatCategories[activeTabIndex].code] : '',
  });

  const { data: malwareDetection } = useGetMalwareDetectionsQuery({
    orgId: OrgId,
  });

  // tab stores the id of the tab which is being displayed
  const [tab, setTab] = useState('');

  // Update the events query filter with corresponding threat type
  const handleSetTab = (index: number) => {
    setActiveTabIndex(index);

    setTableFilters({
      limit: 10,
      offset: 0,
      from: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
      to: new Date().toISOString(),
      sortBy: 'time',
      sortOrder: 'desc',
      threat: threatCategories ? tabCategoryToThreats[threatCategories[index].code] : '',
    });

    setTab(threatCategories?.[index]?.code || '');
  };

  const { data: threats } = useGetThreatStatsQuery(
    {
      orgId: OrgId,
      threatCategory: getThreatCategory(
        threatCategories ? threatCategories[activeTabIndex].code.toLowerCase() : ''
      ),
    },
    {
      skip: threatCategories ? threatCategories.length === 0 : true,
    }
  );

  useEffect(() => {
    // setting up the default tab to display - which is the first threat category which comes from the backend
    if (threatCategories && threatCategories.length > 0) {
      setTab(threatCategories[0].code);
      setTableFilters((prevFilter) => ({
        ...prevFilter,
        threat: threatCategories ? tabCategoryToThreats[threatCategories[0].code] : '',
      }));
    }
  }, [threatCategories, tabCategoryToThreats]);

  const getFallback = () => {
    return tabsLoading ? <Loader /> : <NoThreatFallback />;
  };

  return (
    <div className="mt-3">
      {tabsLoading || (threatCategories && threatCategories.length === 0) ? (
        <div className="w-full justify-center items-center p-6">{getFallback()}</div>
      ) : (
        <Tabs.Root className="flex flex-col w-full" value={tab}>
          <Tabs.List className="flex items-center gap-20 border-b border-border-primary">
            {threatCategories?.map((threatCategory, index) => (
              <Tabs.Trigger
                key={threatCategory.code}
                value={threatCategory.code}
                className={`${activeTabIndex === index ? 'text-black ' : 'text-light-grey'} text-sm flex items-center justify-start`}
                onClick={() => handleSetTab(index)}
              >
                <div
                  className={`flex items-center gap-2 py-3 px-1 ${activeTabIndex === index ? 'border-black border-b-2' : ''}`}
                >
                  <span>{getTabDisplayName(threatCategory.code)}</span>
                  <div className="flex items-center gap-2">
                    <Badge
                      text={`${threatCategory.count || 0}`}
                      bgColor={activeTabIndex === index ? 'black' : 'primary-background'}
                      borderColor={activeTabIndex === index ? 'white' : 'light-grey'}
                      textColor={activeTabIndex === index ? 'white' : 'light-grey'}
                    />
                  </div>
                </div>
              </Tabs.Trigger>
            ))}
          </Tabs.List>
          <div className="mt-8">
            <Tabs.Content id={IMPERSONATION} value={IMPERSONATION}>
              <div className="flex items-stretch gap-4">
                <ThreatInferences
                  className="w-1/4"
                  threatTitle="Impersonation Threats"
                  total={threats?.total || 0}
                  inferences={
                    threats?.threats?.map((threat) => ({
                      ...threat,
                      icon: <Circle color={getLegendColor(threat.code)} />,
                    })) || []
                  }
                  comingSoon={[]}
                />
                <ThreatsFrequency tab="Impersonation" />
                <ThreatInferences
                  className="w-1/4"
                  threatTitle="Impersonation Detections"
                  total={inferenceData?.total || 0}
                  inferences={
                    inferenceData?.hits?.map((hit) => ({
                      ...hit,
                      icon: <ThreatWarningIcon className="W-4 h-4" />,
                    })) || []
                  }
                  comingSoon={[]}
                />
              </div>
              <AllEvents
                title="Impersonation Events"
                isLoading={isLoading}
                filters={tableFilters}
                setFilters={setTableFilters}
                data={flaggedData}
              />
            </Tabs.Content>
            <Tabs.Content id={MALWARE} value={MALWARE}>
              <div className="flex items-stretch gap-4">
                <ThreatInferences
                  className="w-1/4"
                  threatTitle="Malware Threats"
                  total={threats?.total || 0}
                  inferences={
                    threats?.threats?.map((threat) => ({
                      ...threat,
                      icon: <Circle color={getLegendColor(threat.code)} />,
                    })) || []
                  }
                  comingSoon={[]}
                />
                <ThreatsFrequency tab="Malware" />
                <ThreatInferences
                  className="w-1/4"
                  threatTitle="Malware Detections"
                  total={malwareDetection?.total || 0}
                  inferences={
                    malwareDetection?.detections?.map((hit) => ({
                      ...hit,
                      icon: <ThreatWarningIcon className="W-4 h-4" />,
                    })) || []
                  }
                  comingSoon={[]}
                />
              </div>
              <AllEvents
                title="All Malware Events"
                isLoading={isLoading}
                filters={tableFilters}
                setFilters={setTableFilters}
                data={flaggedData}
              />
            </Tabs.Content>
            <Tabs.Content id={BEC} value={BEC}>
              <div className="flex items-stretch gap-4">
                <ThreatInferences
                  className="w-1/4"
                  threatTitle="BEC Threats"
                  total={threats?.total || 0}
                  inferences={
                    threats?.threats?.map((threat) => ({
                      ...threat,
                      icon: <Circle color={getLegendColor(threat.code)} />,
                    })) || []
                  }
                  comingSoon={[]}
                />
                <ThreatsFrequency tab="BEC" />
                <ThreatInferences
                  className="w-1/4"
                  threatTitle="BEC Detections"
                  total={inferenceData?.total || 0}
                  inferences={
                    inferenceData?.hits?.map((hit) => ({
                      ...hit,
                      icon: <ThreatWarningIcon className="W-4 h-4" />,
                    })) || []
                  }
                  comingSoon={[]}
                />
              </div>
              <AllEvents
                title="BEC Events"
                isLoading={isLoading}
                filters={tableFilters}
                setFilters={setTableFilters}
                data={flaggedData}
              />
            </Tabs.Content>
            <Tabs.Content id={INSIDER_THREAT} value={INSIDER_THREAT}>
              <div className="flex items-stretch gap-4">
                <ThreatInferences
                  className="w-1/4"
                  threatTitle="Insider Threats"
                  total={threats?.total || 0}
                  inferences={
                    threats?.threats?.map((threat) => ({
                      ...threat,
                      icon: <Circle color={getLegendColor(threat.code)} />,
                    })) || []
                  }
                  comingSoon={[]}
                />
                <ThreatsFrequency tab="INSIDER_THREAT" />
                <ThreatInferences
                  className="w-1/4"
                  threatTitle="Insider Threat Detections"
                  total={inferenceData?.total || 0}
                  inferences={
                    inferenceData?.hits?.map((hit) => ({
                      ...hit,
                      icon: <ThreatWarningIcon className="W-4 h-4" />,
                    })) || []
                  }
                  comingSoon={[]}
                />
              </div>
              <AllEvents
                title="Insider Threat Events"
                isLoading={isLoading}
                filters={tableFilters}
                setFilters={setTableFilters}
                data={flaggedData}
              />
            </Tabs.Content>
          </div>
        </Tabs.Root>
      )}
    </div>
  );
}
